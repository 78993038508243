import { object, ref, string } from 'yup';

const usuarioSchema = object({
  nome: string().required('O nome é obrigatório'),
  email: string().email('E-mail inválido').required('O e-mail é obrigatório'),
  telefone: string().required('O telefone é obrigatório'),
  senha: string().required('A senha é obrigatório').min(8, 'A senha deve ter no mínimo 8 caracteres'),
  confirmacaoSenha: string().oneOf([ref('senha'), null], 'A senhas devem ser iguais'),
  codigo: string().optional().length(6, 'O código deve ter 6 numeros'),
});

export default usuarioSchema;
