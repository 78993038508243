import { defineComponent, h } from 'vue';
import VMasker from 'vanilla-masker';

type InputType = 'text' | 'password' | 'email' | 'number' | 'tel'
  | 'url' | 'search' | 'date' | 'time' | 'datetime-local'
  | 'month' | 'week' | 'color';
export default defineComponent({
  name: 'FormInput',
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    type: {
      type: String as () => InputType,
      default: 'text',
    },
    mask: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit, attrs }) {
    const onChange = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const value = props.mask ? target.value.replace(/\D/g, '') : target.value;
      emit('update:modelValue', value);
      const maskedValue = props.mask ? VMasker.toPattern(value, props.mask) : value;
      target.value = maskedValue;
    };
    const retorno = h(
      'input',
      {
        id: props.id,
        ...attrs,
        type: props.type,
        name: props.name,
        onInput: onChange,
      },
    );
    return () => retorno;
  },
});
