export default class ErroValidacao {
  public readonly campo: string

  public readonly message: string

  constructor(campo: string, message: string) {
    this.campo = campo;
    this.message = message;
  }
}
