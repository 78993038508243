import axios from 'axios';
import Usuario from '@/types/usuario';
import ErroValidacao from './erroValidacao';

const api = 'https://api.locbase.com.br/';
export default class UsuarioService {
  private error: Record<keyof Usuario, ErroValidacao> = {} as Record<keyof Usuario, ErroValidacao>;

  public async cadastrar(usuario: Usuario) {
    const validacao = await this.validar(usuario);
    if (!validacao) {
      return this.error;
    }
    const retorno = await axios.post<Usuario>(`${api}user/registro`, {
      nome: usuario.nome,
      celular: usuario.telefone,
      email: usuario.email,
      password: usuario.senha,
      password_confirmation: usuario.confirmacaoSenha,
      token: usuario.codigo,
    });
    if (retorno.data.nome) return true;
    return false;
  }

  public async obtenhaCodigoValidacaoCelular(
    usuario: Usuario,
  ): Promise<true | Record<keyof Usuario, ErroValidacao>> {
    const validar = await this.validar(usuario);
    if (validar !== true) {
      return this.error;
    }
    try {
      await axios.post(`${api}user/celular-verificacao`, { celular: usuario.telefone });

      return true;
    } catch (error) {
      return this.error;
    }
  }

  public async validar(usuario: Usuario): Promise<boolean> {
    this.error = {} as Record<keyof Usuario, ErroValidacao>;
    if (usuario.nome === '') {
      this.error.nome = new ErroValidacao('nome', 'O nome é obrigatório!');
    }
    if (usuario.email === '' || !await this.validarEmail(usuario.email)) {
      this.error.email = new ErroValidacao('email', 'O email já está em uso!');
    }
    if (usuario.telefone === '' || !await this.validarCelular(usuario.telefone)) {
      this.error.telefone = new ErroValidacao('telefone', 'O celular já está em uso!');
    }
    if (usuario.senha === '') {
      this.error.senha = new ErroValidacao('senha', 'A senha é obrigatória!');
    }
    if (usuario.confirmacaoSenha === '' || usuario.confirmacaoSenha !== usuario.senha) {
      this.error.confirmacaoSenha = new ErroValidacao('confirmacaoSenha', 'As senhas devem ser iguais!');
    }
    return Object.entries(this.error).length === 0;
  }

  private async validarEmail(email: string): Promise<boolean> {
    if (!this.validateEmail(email)) {
      return false;
    }
    const retorno = (await axios.post<boolean>(
      `${api}validar/email`,
      { email },
    )).data;
    if (retorno === false) {
      this.error.email = new ErroValidacao('email', 'O email já está em uso!');
    }
    return retorno;
  }

  private validateEmail(email: string): boolean {
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const retorno = re.test(email.toLowerCase());
    if (!retorno) this.error.email = new ErroValidacao('email', 'Email inválido!');
    return retorno;
  }

  private async validarCelular(celular: string): Promise<boolean> {
    const retorno = (await axios.post<boolean>(`${api}validar/celular`, { celular })).data;
    if (retorno === false) {
      this.error.telefone = new ErroValidacao('telefone', 'O celular já está em uso!');
    }
    return retorno;
  }
}
