import {
  defineComponent, h,
} from 'vue';
import FormInput from './FormInput';

export default defineComponent({
  name: 'FormControl',
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    messageError: {
      type: String,
    },
  },
  setup(props, { slots }) {
    return () => h(
      'div',
      { class: 'form-control' },
      [h('label', { attrs: { for: props.name } }, [
        slots.default ? slots.default() : h(FormInput, { attrs: { name: props.name } }),
        h('span', { class: 'label' }, props.label),
      ]),
      props.messageError && h('span', { class: 'message' }, props.messageError),
      ],
    );
  },
});
